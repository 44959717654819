<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8" align-self="center">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${data_table.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="login.permissions.rha_openings.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="formAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Fecha'" />
                          <th v-text="'N. Siniestro'" />
                          <th v-text="'Suma remanente'" />
                          <th v-text="'Núm. reclamación'" />
                          <th v-text="'Descripción'" />
                          <th v-text="'Acción'" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, i) in data_table" :key="i">
                          <td
                            class="font-weight-bold"
                            v-text="data_table.length - i"
                          />
                          <td v-text="v.created_at" />
                          <td v-text="v.sinister_number" />
                          <td v-text="numberFormat(v.remaining_sum)" />
                          <td v-text="v.claim_number" />
                          <td
                            class="text-description"
                            v-text="
                              v.description
                                ? v.description.length > 300
                                  ? `${v.description.substr(0, 300)}...`
                                  : v.description
                                : ''
                            "
                          />
                          <td width="150px">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="secondary"
                                  @click.prevent="formEditView(v.id, false)"
                                >
                                  <v-icon v-text="'mdi-eye'" />
                                </v-btn>
                              </template>
                              <span v-text="'Ver'" />
                            </v-tooltip>
                            <v-tooltip
                              left
                              v-if="login.permissions.rha_openings.update"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="info"
                                  @click.prevent="formEditView(v.id, true)"
                                >
                                  <v-icon v-text="'mdi-pencil'" />
                                </v-btn>
                              </template>
                              <span v-text="'Editar'" />
                            </v-tooltip>
                            <v-tooltip
                              left
                              v-if="login.permissions.rha_openings.delete"
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  x-small
                                  fab
                                  outlined
                                  class="mr-1"
                                  color="error"
                                  @click.prevent="desactivate(v.id)"
                                >
                                  <v-icon v-text="'mdi-database-remove'" />
                                </v-btn>
                              </template>
                              <span v-text="'Eliminar'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="loading ? 'grey darken-2' : false"
        :disabled="loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="data.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="formDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'APERTURA'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="N. siniestro*"
                          v-model="data.sinister_number"
                          :rules="rules.text10Required"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="Suma remanente*"
                          v-model="data.remaining_sum"
                          :rules="rules.required"
                          type="number"
                          dense
                          min="0"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          label="Núm. reclamación*"
                          v-model="data.claim_number"
                          :rules="rules.required"
                          dense
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="data.description"
                          label="Descripción"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'DOCUMENTOS'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items />
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col
                        cols="12"
                        v-for="(v, i) in data.documents"
                        :key="i"
                      >
                        <v-row dense v-if="v.active">
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              :label="`Descripción (${i + 1})*`"
                              v-model="v.description"
                              :rules="rules.required"
                              type="text"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="5">
                            <v-file-input
                              label="Archivo digital*"
                              v-model="v.file"
                              accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                              :rules="data.id ? [] : rules.required"
                              show-size
                              dense
                            />
                          </v-col>
                          <v-col cols="12" xs="12" md="1" class="text-right">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  small
                                  color="error"
                                  @click.prevent="fileDelete(i)"
                                >
                                  <v-icon medium v-text="'mdi-file-remove'" />
                                </v-btn>
                              </template>
                              <span v-text="`Eliminar documento (${i + 1})`" />
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12">
                            <v-divider class="pb-1" />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              x-small
                              color="warning"
                              :loading="loading"
                              @click.prevent="fileAdd"
                            >
                              <v-icon v-text="'mdi-file-plus'" />
                            </v-btn>
                          </template>
                          <span v-text="'Agregar documento'" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  :tLabel="data.id ? 'Editar' : 'Agregar'"
                  tPosition="top"
                  :icon="`mdi-${data.id ? 'pencil' : 'plus'}`"
                  :color="data.id ? 'info' : 'success'"
                  size="small"
                  :click="formSubmit"
                  :loading="loading"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="view_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'APERTURA'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="N. siniestro"
                        :value="data.sinister_number"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="Suma remanente"
                        :value="numberFormat(data.remaining_sum)"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <ViewData
                        label="Núm. reclamación"
                        :value="claim_number"
                      />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Descripción"
                        :value="data.description"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DOCUMENTOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense v-for="(document, i) in data.documents" :key="i">
                    <v-col cols="12" sm="12" md="10">
                      <ViewData
                        :label="`Descripción (${i + 1})*`"
                        :value="document.description"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="ml-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            dark
                            :href="
                              url_documents + '/rha_opening/' + document.url
                            "
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="pb-1" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      api_url: "rha/openings",
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      loading: false,
      rules: rules(),
      data_table: [],
      data: {},
      form_dialog: false,
      view_dialog: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    getDataTable() {
      this.loading = true;

      index(this.api_url, this.login.token, [
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });

      this.data_table = [];
    },
    defaultData() {
      this.data = {
        id: null,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.rha_id,
        documents: [],
        sinister_number: "",
        remaining_sum: "0",
        claim_number: "",
      };
    },
    formAdd() {
      this.form_dialog = true;
    },
    formEditView(id, edit) {
      show(this.api_url, this.login.token, id).then((response) => {
        this.data = response;

        if (edit) {
          this.form_dialog = true;
        } else {
          this.view_dialog = true;
        }
      });
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData();
      this.$refs.form_submit.resetValidation();
    },
    formSubmit() {
      if (this.data.documents.length > 0) {
        if (this.$refs.form_submit.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma ${this.data.id ? "editar" : "agregar"} el registro?`
              )
            )
            .then((response) => {
              if (response.isConfirmed) {
                this.loading = true;

                storeUpdate(
                  this.api_url,
                  this.login.token,
                  this.data.id,
                  this.data,
                  this.login.id
                ).then((response) => {
                  this.$swal.fire(
                    msgAlert(
                      response.success ? "success" : "error",
                      response.message
                    )
                  );

                  if (response.success) {
                    this.getDataTable();
                    this.defaultData();
                    this.form_dialog = false;
                  } else {
                    console.log(response.message);
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert("error", "Se debe de cargar mínimo 1 documento")
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(this.api_url, this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getDataTable()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        description: "",
        active: true,
      });
    },
    fileDelete(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    this.defaultData();
    this.getDataTable();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>